import React from "react";



const IsometricMockups = () => {
  return <section className="isometric-mockups">
      <img  className="desktop4" src={require(`assets/img/screens/dashboardfinal1.png`)} alt="..." />
    </section>;
};

export default IsometricMockups;
