import React from "react";
import { Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fade } from "react-reveal";

const FeaturesCantMiss = () => {
  return (
    <section className="section features-cant-miss">
      <div className="shapes-container overflow-clear">
        <div className="shape shape-triangle shape-animated">
          <div className="animation--rotating" />
        </div>
      </div>
      <Container>
        <Row className="gap-y">
          <Col md={{ size: 6, order: "last" }}>
            <br /><br /><br />
            <div className="section-heading">
              <h2 className="heading-line">Features to help you<br/> and your team shine:</h2>
          
            <ul className="list-unstyled">
              <li className="media flex-column flex-md-row text-center text-md-left">
                <div className="media-body mt-md-0">
                  <h5 className="mt-0 mb-1"><b>OPTIMIZE FOR COST</b></h5>
                  <p className="lead m-0">
                  Find and reduce waste throughout your customer service channels </p>
                </div>
              </li>

              <li className="media flex-column flex-md-row text-center text-md-left mt-3">
                <div className="media-body mt-md-0">
                  <h5 className="mt-0 mb-1"><b>ELIMINATE BOTTLENECKS</b></h5>
                  <p className="lead m-0 ">
                  Identify holdups and breakdowns as they’re happening, and solve them  </p>
                </div>
              </li>

              <li className="media flex-column flex-md-row text-center text-md-left mt-3">

                <div className="media-body mt-md-0">
                  <h5 className="bold mt-0 mb-1"><b>CREATE ACTIONABLE INSIGHTS</b></h5>
                  <p className="lead m-0">

                  Translate trends and patterns into steps that drive team productivity </p>
                </div>
              </li>

              <li className="media flex-column flex-md-row text-center text-md-left mt-3 ">

                <div className="media-body mt-md-0">
                  <h5 className="bold mt-0 mb-1"><b>FORECAST YOUR RESULTS
</b></h5>
                  <p className="lead m-0">
                  Get out of reaction mode and shift to a real-time, proactive approach  </p>
                </div>
              </li>
              <li className="media flex-column flex-md-row text-center text-md-left mt-3 ">

                <div className="media-body mt-md-0">
                  <h5 className="bold mt-0 mb-1"><b>INTEGRATIONS
</b></h5>
                  <p className="lead m-0">
                  Sunlight seamlessly integrates with popular ticketing systems such as Freshdesk, Zendesk, ServiceNow and MS Dynamics CRM. And we can integrate with any platform on-demand, including Salesforce.
 </p>
                </div>
              </li>
            </ul>
            </div>
          </Col>

          <Col md="6" className="my-auto mx-auto">
            <div  >
              <img
              className="side-image2 mx-auto"
                  src={require("assets/img/screens/sunlight_user_profile.png")}
                  alt="..."
                />

            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default FeaturesCantMiss;
