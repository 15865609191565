import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import DefaultNavbar from '../../components/Navbars/DefaultNavbar';
import DefaultFooter from '../../components/Footers/DefaultFooter';
import Animationfade from 'views/shared/Animationfade';
import imgurl from '../../assets/img/graph.jpg';

const CaseStudy = (props) => {
  return (
    <>
      <DefaultNavbar inner={true} />
      <main ref={React.createRef('main')}>
        <div className='header alter3-header section'>
          <Animationfade />
          <Container>
            <h2 className='heading-line'>Case Study: Global software utility organization</h2>
            <Row>
              <Col lg='6'>
                <div className='section-heading mt-5'>
                  <h3 className='text-alternate heading-line'>
                  About the Customer:
                  </h3>
                  <ol>
                    <li>Global software utility organization</li>
                    <li>Ticket volume: 1400 tickets per week</li>
                    <li>Ticket management tool: ZenDesk</li>
                  </ol>
                </div>
              </Col>
              <Col lg='6'>
                <div className='section-heading mt-5'>
                  <h3 className='text-alternate heading-line'>
                    Business Challenge:
                  </h3>
                  <ol>
                    <li>Large number of users across the globe</li>
                    <li>
                    High Initial Response Time (IRT) of 7 hours and Average Resolution Time (ART) of 48 hours
                    </li>
                  </ol>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg='6'>
                <div className='section-heading mt-5'>
                  <h3 className='text-alternate heading-line'>Sunlight Solution:</h3>
                  <ol>
                    <li>Sunlight deployed Prism and Bubble solution for customer</li>
                    <li>
                    Identified scheduling issue and knowledge gap issue using insights from Prism to reduce IRT
                    </li>
                    <li>
                    Identified opportunities for cross training to increase efficiency
                    </li>
                    <li>
                    Leveraged Bubble to assist in faster ticket resolution
                    </li>
                    <li>
                    Performed sentiment analysis to recommend business process changes
                    </li>
                  </ol>
                </div>
              </Col>
              <Col lg='5'>
                <div
                  style={{ height: '350px', padding: '0 100px' }}
                  className='rounded overflow-hidden '
                >
                  <img src={imgurl} className='img-responsive' alt='' />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={{sixe: 12}}>
                <h3 className='text-alternate heading-line'>Benefits:</h3>
                <ol>
                  <li>40% reduction in Initial Response Time</li>
                  <li>10% reduction in Average Response Time</li>
                  <li>Sentiment analysis helped to identify real pain point</li>
                </ol>
              </Col>
            </Row>
          </Container>
        </div>
      </main>
      <DefaultFooter />
    </>
  );
};

export default CaseStudy;
