import React from "react"
import { Row, Container, Col } from "reactstrap"
import Animationfade from "views/shared/Animationfade"

// core components
import DefaultNavbar from "../../components/Navbars/DefaultNavbar"
import DefaultFooter from "../../components/Footers/DefaultFooter"

const BlogData = [
    {
        title: "Recent Changes in Customer Support Function ",
        date: "June 30,2020",
        img: "https://www.thesunlight.ai/assets/images/blog/Umesh_blog_2.jpg",
        link:
            "https://medium.com/@roy_89769/recent-changes-in-customer-support-function-15fa7aa73883",
    },
    {
        title: "Customer Experience: What is working and What is Not?",
        date: "June 22,2020",
        img: "https://www.thesunlight.ai/assets/images/blog/blog_umesh_1.png",

        link:
            "https://medium.com/@roy_89769/customer-experience-what-is-working-and-what-is-not-cf9ffd53b389",
    },
    {
        title: "Work From Home: The Good Parts! (Stay productive and healthy)",
        date: "June 02,2020",
        img: "https://www.thesunlight.ai/assets/images/blog/DSD_WFH.jpg",

        link:
            "https://medium.com/@vijay.jagoori/work-from-home-the-good-parts-5ef0f7b54fe1",
    },
    {
        title:
            "Transform Customer experience with the analytics and automation powered by Sunlight AI",
        date: "May 18,2020",
        img: "https://www.thesunlight.ai/assets/images/blog/dsd_case_1.png",

        link:
            "https://medium.com/@vijay.jagoori/transform-customer-experience-with-the-analytics-and-automation-powered-by-Sunlight-ai-ef0a2c92935b",
    },
    {
        title: "The Top 5 Blockers To Your Productivity",
        date: "January 3,2020        ",
        img: "https://www.thesunlight.ai/assets/images/blog/1_Image.png",

        link:
            "https://medium.com/@prateek.dsd/the-top-5-blockers-to-your-productivity-666767a52bdf",
    },
    {
        title: "Managing B2C Customer Support Escalations",
        date: "December 29,2019 ",
        img: "https://www.thesunlight.ai/assets/images/blog/2_Image.png",
        link:
            "https://medium.com/@prateek.dsd/managing-b2c-customer-support-escalations-c65a4587f0c7",
    },
    {
        title: "Managing B2C Customer Support Escalations",
        date: "December 28,2019        ",
        img: "https://www.thesunlight.ai/assets/images/blog/3_Image.png",
        link:
            "https://medium.com/@prateek.dsd/managing-b2b-customer-support-escalations-4900b7782007",
    },
    {
        title: "SEVEN major problems running Tech Support",
        date: "December 11,2019        ",
        img: "https://www.thesunlight.aiassets/images/blog/4_Image.png",
        link:
            "https://medium.com/@prateek.dsd/seven-major-problems-running-tech-support-1e2252d93889",
    },
    {
        title: "Why Customer Support Is Key To Success For Enterprise Software Companies",
        date: "December 07,2019  ",
        img: "https://www.thesunlight.ai/assets/images/blog/5_Image.png",
        link:
            "https://medium.com/@prateek.dsd/why-customer-support-is-key-to-success-for-enterprise-software-companies-ef827c27bdda",
    },
    {
        title:
            "Drive Your Customer Support Teams to Deliver Stellar Support and Increase Customer Satisfaction",
        date: "December 07,2019 ",
        img: "https://www.thesunlight.ai/assets/images/blog/6_Image.jpg",
        link:
            "https://medium.com/@prateek.dsd/drive-your-customer-support-teams-to-deliver-stellar-support-and-increase-customer-satisfaction-4cb0f12f3757",
    }
]

export default () => {
    return (
        <>
            <DefaultNavbar inner={true} />
            <main ref={React.createRef("main")}>
                <div className="header alter3-header section">
                    <Animationfade/>

                    <Container>
                        <Row className="gap-y align-items-center">
                            {BlogData.map(({ title, date, link, img }) => (
                                <Col md="4">
                                    <div className="card shadow-box shadow-hover">
                                        <img
                                            src={img}
                                            alt="Card cap"
                                            className="card-img-top"
                                        />
                                        <div className="card-body">
                                            <h5 className="card-title">{title}</h5>
                                            <p className="card-text">{date} </p>
                                            <a
                                                href={link}
                                                target="_blank"
                                                className="btn btn-primary"
                                                rel="noopener noreferrer"
                                            >
                                                Read full article
                                            </a>
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Container>
                </div>
            </main>
            <DefaultFooter />
        </>
    )
}
