import React,{useEffect} from "react"

// shared page components
// import Heading from "../shared/Heading"

import FeaturesCard from "../solutions/FeaturesCard"
// core components
import DefaultNavbar from "../../components/Navbars/DefaultNavbar"
import DefaultFooter from "../../components/Footers/DefaultFooter"

export default () => {
    useEffect(()=>{
        window.scrollTo(0,0);
    })
    return (
        <>
            <DefaultNavbar inner={true} />
            <main ref={React.createRef("main")}>
                {/* <Heading /> */}
                <FeaturesCard />
            </main>
            <DefaultFooter />
        </>
    )
}
