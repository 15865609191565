import React from 'react';
import { Container, Row, Col, Nav, NavLink } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Animationfade from 'views/shared/Animationfade';

import image_1 from '../../assets/img/screens/tablet/Sunlight_D1.png';
import image_2 from '../../assets/img/Sunlight_Gratitude.png';
import image_3 from '../../assets/img/Sunlight_Bubble_1.png';
import image_4 from '../../assets/img/screens/tablet/Sunlight_D3.png';
import image_5 from '../../assets/img/solutions/5.png';
import image_6 from '../../assets/img/solutions/6.jpeg';

const cards = [
  {
    imgurl: image_1,
    title: 'Prism - Customer Support Analytics',
    description: `Sunlight’s analytics module, Prism, extracts meta-data and content from CRM systems such as Freshdesk, Zendesk, Dynamics CRM, and ServiceNow to generate the most significant KPIs for measuring customer experience. Prism uses NLP to generate sentiment and text analytics.`,
    benefits: `
                ● Identify and remove all customer pain points
                ● Scale without adding more cost
                ● Enhance your team’s productivity and efficiency `,
  },
  {
    imgurl: image_2,
    title: 'Gratitude - Real-time Net Promoter Score (NPS) survey',
    description: `Sunlight has developed an integrated NPS-based survey service that can be used per transaction or as a campaign.`,
    benefits: `
                ● Capture customer voice in real-time with every ticket closed
                ● Increase customer engagement
                ● Eliminate the need for manual export and reconciliation of data from 3rd party survey system
                ● Improve product and services continuously based on customer feedback`,
  },
  {
    imgurl: image_3,
    title: 'Bubble - AI & Machine Learning Agent Support',
    description: `Our Bubble application is an AI based agent support for Freshdesk, Zendesk, ServiceNow and Microsoft Dynamics. Bubble engages with CRM systems to learn from every interaction between customer and customer support agent. It also engages with your existing knowledge base. `,

    benefits: `
                ● Faster response and resolution of tickets
                ● Standardized solution for every ticket with less human error
                ● Faster ramp up of new agents
                ● Scale operations without adding significant cost`,
  },
  {
    imgurl: image_4,
    title: 'Customer Support Transformation consultancy',
    description: `Sunlight’s consultancy team has transformed several customer service organizations through side-by-side engagement. Our team applies Lean SIx SIgma methodologies to identify & remove waste across all pillars such as people, processes and tools. The goal is to turbo-charge customer support organizations for growth, sustain and improve profitability, increase end-customer satisfaction, and de-risk their business from future uncertainties.

          ● Engagements typically last 4-6 weeks employing powerful problem-solving approaches such as DMAIC​,​ 5S System​, 5-Whys​, and time and motion studies
          ● Our team runs experiments to identify and capture value as part of our continuous improvement effort
          ● We also identify automation opportunities to reduce efforts and cost
          ● Our team drills down on training needs and knowledge gaps to help build high-performance teams
          ● All business processes are documented and standardized`,
    benefits: `
                ● Instant productivity gain, leading to instant profit gain
                ● Improve scalability with standardized and optimized processes and tools without adding cost
                ● Well-documented playbooks in place to help ramp up new member quickly`,
  },
];

const RanderCards = ({ i, item }) => {
  const { title, description, benefits, imgurl } = item;
  return (
    <>
      {i % 2 === 0 ? (
        <div style={{ height: '500px' }} className='rounded overflow-hidden '>
          <img src={imgurl} className='img-responsive' alt='' />
        </div>
      ) : (
        <div className='section-heading'>
          <h2 className='heading-line'>{title}</h2>
          <p className='lead text-muted'>{description}</p>
          <b className='text-muted'>Benefits : </b>
          <p className='lead regular text-muted'>{benefits}</p>
        </div>
      )}
    </>
  );
};

const FeaturesCard = () => {
  return (
    <section className='section powered-design'>
      <Animationfade />

      <Container>
        <Row
          style={{ whiteSpace: 'pre-line' }}
          className='gap-y align-items-center'
        >
          {cards.map((item, i) => (
            <>
              <Col md='6'>
                <RanderCards i={i} item={item} />
              </Col>
              <Col md='6'>
                <RanderCards i={i + 1} item={item} />
              </Col>
            </>
          ))}

        </Row>
        <Row
          style={{ whiteSpace: 'pre-line' }}
          className='gap-y align-items-center'
        >
          <Col md='6'>
            <div className='rounded overflow-hidden '>
              <img src={image_5} className='img-responsive' alt='' />
            </div>
            </Col>
            <Col md='6'>
            <div className='section-heading'>
              <h2 className='heading-line'>{`How do we work?`}</h2>
              <h5 className='bold mt-0 mb-1'>Week 1</h5>
              <p className='lead text-muted'>We spend the first week assessing the current business process, knowledge repositories, and CRM setup. We recommend process changes as required. Our team also identifies any customization required to implement our suite of products and will integrate solutions with the CRM sandbox if available.</p>
              <h5 className='bold mt-0 mb-1'>Week 2,3</h5>
              <p className='lead text-muted'>Sunlight runs trials with the Sandbox to fine-tune Sunlight modules for maximum efficiency. We also work with managers to update business processes for best practice. At the end of week 3, Sunlight demonstrates the new capabilities and benefits.</p>
              <h5 className='bold mt-0 mb-1'>Week 4</h5>
              <p className='lead text-muted'>Upon acceptance of the proposed solution, Sunlight integrates the solution within the production environment to start gathering data for metrics and insights.</p>
            </div>
          </Col>

            <Col md='6'>
            <div className='section-heading'>
              <h2 className='heading-line'>{`Compliance`}</h2>
          <p className='lead text-muted'>{`
                ● We take data privacy and security seriously and implement all best practices to maintain the highest level of trust and security  
                ● Our customers own and control all of their data
                ● We minimize data exposure and mask data appropriately
                ● We never share or repurpose customer data with anyone 
                ● Customer data can be deleted anytime upon request
                ● Data is encrypted whenever transferred between the Sunlight software and customer ticketing systems `}</p>
              <p className='lead text-muted'></p>
            </div>
          </Col>
          <Col md='6'>
            <div className='rounded overflow-hidden '>
              <img src={image_6} className='img-responsive' alt='' />
            </div>
            </Col>
        </Row>
        <Row>
          <Col lg={{ size: '12', offset: 5 }}>
            <Nav className='mt-5' tag='nav'>
              <NavLink
                href='/contactus'
                className='mr-3 btn btn btn-rounded btn-contrast'
                style={{backgroundColor: 'rgb(103, 151, 234)'}}
              >
                <FontAwesomeIcon icon={['fas', 'tag']} className='mr-3' />
                Request demo
              </NavLink>
            </Nav>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default FeaturesCard;
