import React from "react"
import { Container, Row,Col, Nav, NavLink } from "react-bootstrap"




const Integration = () => {
    return (
        <section className="section integration-bubbles">
        <Container className="overflow-hidden">
            <Row className="justify-content-md-center">
                <div className="section-heading">
                    <h3 className="flex-column flex-md-row align-items-center justify-content-center bold mx-auto">
                       “Sunlight’s efficient platform allowed us to double our user base without adding agents.”
                    </h3>
                    <span className="nav flex-column flex-md-row align-items-center justify-content-center bold mx-auto">
                        CTO, Leading Medical Device Co.
                    </span>
                    <br/>
                    <div className="nav flex-column flex-md-row align-items-center justify-content-center">
                 <Nav className="mt-2 " tag="nav">
                    <NavLink
                        href="/contactus"
                        className="mr-3 btn btn btn-rounded btn-primary"
                    >
                        Request demo
                    </NavLink>
                    <NavLink
                        href="/contactus"
                        className="btn btn-rounded btn-outline-primary"
                    >
                        Try Sunlight free for 60 days
                    </NavLink>
                </Nav>
                </div>
                    </div>
                    </Row>
        </Container>
    </section>

           )
}

export default Integration
