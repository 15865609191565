import React from "react"
import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap"
// import Odometer from "react-odometerjs"

// core components
import DefaultNavbar from "../../components/Navbars/DefaultNavbar"
import DefaultFooter from "../../components/Footers/DefaultFooter"
import Animationfade from "views/shared/Animationfade"

import "odometer/themes/odometer-theme-minimal.css"

export default () => {
    return (
        <>
            <DefaultNavbar inner={true}/>
            <main ref={React.createRef("main")}>
                <div className="header alter3-header section">
                    <Animationfade />

                    <Container>
                        <h1 className="heading-line">Pricing</h1>
                        <Row className="no-gutters align-items-center pricing-plans-options">
                            <Col
                                lg="4"
                                className="pricing-plan plan-enterprise order-md-last best-value"
                            >
                                <div className="py-md-6 p-5">
                                    <div className="text-center">
                                        <h4 className="bold text-capitalize text-primary">
                                            PTP
                                        </h4>

                                        {/* <div className="pricing-details">
                                            <span className="pricing-value">
                                                <span className="price">
                                                    <Odometer duration={500} value={} />
                                                </span>
                                            </span>
                                        </div> */}
                                    </div>
                                    <hr />
                                    <div className="d-none d-md-flex">
                                        <ListGroup flush className="list-group-no-border">
                                            <ListGroupItem>
                                            Per ticket processing cost
                                            </ListGroupItem>
                                        </ListGroup>
                                    </div>
                                </div>
                            </Col>
                            <Col
                                lg="4"
                                className="pricing-plan ml-5 plan-enterprise order-md-last best-value"
                            >
                                <div className=" py-md-6 p-5">
                                    <div className="text-center">
                                        <h4 className="bold text-capitalize text-primary">
                                            Enterprise
                                        </h4>
                               

                                        {/* <div className="pricing-details">
                                            <span className="pricing-value">
                                                <span className="price">
                                                    <Odometer
                                                        duration={500}
                                                        // value={plan.price[props.basis]}
                                                    />
                                                </span>
                                            </span>
                                        </div> */}
                                    </div>
                                    <hr />
                                    <div className="d-none d-md-flex">
                                        <ListGroup flush className="list-group-no-border">
                                            <ListGroupItem>
                                            Per month cost with unlimited tickets
                                            </ListGroupItem>
                                        </ListGroup>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row className="align-items-center mt-4">
                            <div className="text-center text-md-left">
                                <h4 className="bold accent">
                                Not sure what's best for you?
                                </h4>
                                <p className="mt-0">
                                For more information, please contact ​info@thesunlight.ai
                                </p>
                            </div>
                        </Row>
                    </Container>
                </div>
            </main>
            <DefaultFooter />
        </>
    )
}
