import React from "react";
import { Container, Row, Col,Nav,NavLink } from "reactstrap";

const Partners = () => {
  return (
    <section className="section alter3-partners bg-contrast edge top-left">
      <Container className="bring-to-front pb-md-9">
        <Row>
          <Col md="6">
           <div className="mb-0">
              <h3 className="text-alternate heading-line">
              A global software <br/> company used Sunlight to 
              </h3>

              <p className="bold">
                <ul>
               <li>Reduce Initial Response Time by 40%,</li>
               <li>Cut Average Resolution Time by 30%,</li>
               <li>Transform 30% of tickets to one-click resolution within 6 weeks.</li>
               </ul>
               <br/>
                </p>
                </div>
             <div className="mt-0">              <h3 className="text-alternate heading-line">
              A global player in <br/>medical solutions used Sunlight to
              </h3>
              <p className="bold">
                <ul>
               <li>More than double their NPS to over 80 </li>
               <li>Double customers without adding any agents</li>
               <li>Deliver world class customer experience with agents spread across multiple continents.
</li>
               </ul>
               <br/>
                </p>
                </div>


            {/* <Nav>{renderPartners()}</Nav> */}

            <Nav tag="nav">
              <NavLink
                href="/contactus"
                className="mr-3 btn btn btn-rounded btn-primary"
              >
                Request demo
              </NavLink>

              <NavLink
                        href="/contactus"
                        className="btn btn-rounded btn-outline-primary"
                    >
                        Try Sunlight free for 60 days
                    </NavLink>
            </Nav>

          </Col>
          <Col md="6">
              <div>
              <img
                 className="side-image mx-auto"
                  src={require("assets/img/screens/sunlight_dashboard.png")}
                  alt="..."
                />
              </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Partners;
