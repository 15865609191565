import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// routing components
import Home from "./views/pages/Home";
import Solutions from "./views/pages/Solutions";
import ContactUs from "./views/pages/ContactUs";
import Blog from "./views/pages/Blog";
import CaseStudy from "./views/pages/CaseStudy";
import Career from "./views/pages/Career";
import About from "./views/pages/About";
import Pricing from "./views/pages/Pricing";
import Integration from "./views/pages/Integration";


// global template script file
import "./laapp.js";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route
        path="/"
        exact
        render={props => <Home {...props} />}
      />
      <Route
        path="/solutions"
        exact
        render={props => <Solutions {...props} />}
      />
      {/* <Route path='/contactus' component={() => { 
     window.location.href = 'https://www.thesunlight.ai/users/demo/contactus'; 
     return null;
        }}/> */}
      <Route
        path="/contactus"
        exact
        render={props => <ContactUs {...props} />}
      />
      <Route
        path="/blog"
        exact
        render={props => <Blog {...props} />}
      />
      <Route
        path="/case-study"
        exact
        render={props => <CaseStudy {...props} />}
      />
      <Route
        path="/about"
        exact
        render={props => <About {...props} />}
      />
      <Route
        path="/career"
        exact
        render={props => <Career {...props} />}
      />
      <Route
        path="/pricing"
        exact
        render={props => <Pricing {...props} />}
      />
      <Route
        path="/integration"
        exact
        render={props => <Integration {...props} />}
      />
      <Redirect to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
