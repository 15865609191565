import React from "react"
import Fade from "react-reveal/Fade"

export default () => {
    return (
        <div className="powered-design">
            <div className="shapes-container">
                <div className="shape shape-circle shape-circle-1">
                    <Fade bottom right duration={1500}>
                        <div />
                    </Fade>
                </div>
                <div className="shape shape-circle shape-circle-2">
                    <Fade bottom right duration={1200} delay={500}>
                        <div />
                    </Fade>
                </div>
                <div className="shape shape-ring animation--rotating-diagonal">
                    <div />
                </div>
                <div className="shape shape-triangle shape-animated">
                    <div className="animation--rotating" />
                </div>
                <div className="shape pattern-dots-1" />
            </div>
        </div>
    )
}
