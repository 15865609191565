import React, { useState } from 'react';
import Tilt from 'react-tilt';
import Fade from 'react-reveal/Fade';

const PowerfulCard = (props) => {
  const [isShown, setIsShown] = useState(false);

  return (
    <Fade top {...props.animation}>
                <h4>{props.title}</h4>
      <Tilt options={{ max: 20, glare: true, maxGlare: 0.4 }}>
        <div className='card border-0 shadow mb-5 tilt'>
        <img src={require(`assets/img/screens/slide/${props.img}`)} alt="..." />
        </div>
      </Tilt>
    </Fade>
  );
};

export default PowerfulCard;
