import React from "react"

// shared page components
import PowerfulFeatures from "../shared/PowerfulFeatures"
import FeaturesCantMiss from "../shared/FeaturesCantMiss"


import Integration from "../shared/Integration"
import WhyUs from "../shared/WhyUs"
import Faqs from "../shared/Faqs"
import StartFree from "../shared/StartFree"
// import Subscribe from "../shared/Subscribe"

// core components
import DefaultNavbar from "../../components/Navbars/DefaultNavbar"
import DefaultFooter from "../../components/Footers/DefaultFooter"

// alternative 3 page components
import Heading from "../shared/Heading"

import Partners from "../home/Partners"

class Home extends React.Component {
    render() {
        window.scrollTo(0,0);
        return (
            <>
                            <main ref="main">
                <DefaultNavbar />
                    <Heading />
                    <Partners />
                    <PowerfulFeatures />
                    <FeaturesCantMiss />
                    <Integration />
                    <Faqs />
                    <StartFree />
                    {/* <Subscribe /> */}
                </main>
                <DefaultFooter />
            </>
        )
    }
}

export default Home
