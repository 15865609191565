import React from "react"
import { Link } from "react-router-dom"
import classNames from "classnames/bind"

// reactstrap components
import {
    NavbarBrand,
    NavItem,
    NavLink,
    Nav,
    Container,
    Button,
    Dropdown,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
} from "reactstrap"

class DefaultNavbar extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            navbarExpanded: false,
            resourcesdropdownOpen: false,
            compnayropdownOpen: false,
            solutionsdropdownOpen:false,
        }

        this.navbar = React.createRef()

        this.toggleNavbar = this.toggleNavbar.bind(this)
    }

    toggleNavbar() {
        this.setState((prevState) => ({
            navbarExpanded: !prevState.navbarExpanded,
        }))
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll)
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll)
    }

    handleScroll = () => {

        const navigation = this.navbar.current
        const navTop = navigation.offsetTop + navigation.offsetHeight

        if (window.scrollY >= navTop) {
            navigation.classList.add("navbar-sticky")
        } else {
            navigation.classList.remove("navbar-sticky")
        }
    }

    toggleRes = () => {
        this.setState(({ resourcesdropdownOpen }) => ({
            resourcesdropdownOpen: !resourcesdropdownOpen,
        }))
    }
    toggleSolu=()=>{
        this.setState(({
           solutionsdropdownOpen 
        })=>({
            solutionsdropdownOpen:!solutionsdropdownOpen,
        }))
    }

    toggleCom = () => {
        this.setState(({ compnayropdownOpen }) => ({
            compnayropdownOpen: !compnayropdownOpen,
        }))
    }

    onRedirect = () => {
        window.location.href = 'https://webapp.readyly.com/login';
    }

    render() {
        const useOnlyDarkLogo = this.props.useOnlyDarkLogo
        const innerClass = this.props.inner ? 'navbar-inner' : '';
        return (
            <nav
                ref={this.navbar}
                className={classNames(
                    `navbar navbar-expand-md main-nav navigation fixed-top sidebar-left ${innerClass}`,
                    { "navbar-expanded": this.state.navbarExpanded }
                    
                )}
            >
                <Container>
                    <button
                        className="navbar-toggler"
                        type="button"
                        onClick={this.toggleNavbar}
                    >
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                    </button>

                    <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                       {this.props.inner ? (                      <img
                                alt="..."
                                src={require("assets/img/sunlight_ai.png")}
                                className="logo logo-sticky img-responsive"
                                style={{maxWidth: "300px"}}
                            />) : (

                        useOnlyDarkLogo ? (
                            <img
                                alt="..."
                                src={require("assets/img/sunlight_ai.png")}
                                className="logo logo-sticky img-responsive"
                                style={{maxWidth: "300px"}}
                            />
                        ) : (
                            <>
                                <img
                                    src={require("assets/img/sunlight_ai.png")}
                                    alt="Sunlight"
                                    className="logo logo-sticky d-block d-md-none img-responsive"
                                    style={{maxWidth: "300px"}}
                                />
                                <img
                                    src={require("assets/img/sunlight_ai.png")}
                                    alt="Sunlight"
                                    className="logo d-none d-md-block img-responsive"
                                    style={{maxWidth: "300px"}}
                                />
                            </>
                        ))}
                    </NavbarBrand>

                    <div className="collapse navbar-collapse">
                        <div className="sidebar-brand">
                            <Link to="/">
                                <img
                                    src={require("assets/img/sunlight_logo.png")}
                                    alt="Laapp Template"
                                    className="logo"
                                />
                            </Link>
                        </div>

                        <Nav className="nav navbar-nav ml-auto" navbar>
                           {/* <NavItem>
                                <NavLink tag={Link} to="/solutions">
                                    Solutions
                                </NavLink>
                           </NavItem>*/}
                            {/* <NavItem>
                                <Dropdown
                                    nav
                                    isOpen={this.state.resourcesdropdownOpen}
                                    toggle={this.toggleRes}
                                >
                                    <DropdownToggle nav caret>
                                        Resources
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem tag={Link} to="/blog">
                                            Blog
                                        </DropdownItem>
                                        <DropdownItem tag={Link} to="/case-study">
                                            Case studies
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </NavItem> */}
                           {/* <NavItem>
                                <Dropdown
                                    nav
                                    isOpen={this.state.compnayropdownOpen}
                                    toggle={this.toggleCom}
                                >
                                    <DropdownToggle nav caret>
                                        Company
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem tag={Link} to="/about">
                                            About
                                        </DropdownItem>
                                        <DropdownItem tag={Link} to="/career">
                                            Career
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </NavItem>
                           <NavItem>*/}
                           <NavItem>
                                {/* <NavLink tag={Link} to="/contactus"> */}
                                <NavLink tag={Link} onClick={this.onRedirectContact} to="/contactus">
                                    Contact
                                </NavLink>
                            </NavItem>
                            {/* <NavItem>
                                <NavLink tag={Link} to="/Pricing">
                                    Pricing
                                </NavLink>
                            </NavItem> */}
                            <NavItem className="m-auto">
                                <Button onClick={this.onRedirect} className="btn btn-alternate px-4 bold mr-0 mr-md-3 mb-3 mb-md-0">
                                
                                    Login
                                </Button>
                            </NavItem>
                        </Nav>
                    </div>
                </Container>
            </nav>
        )
    }
}

export default DefaultNavbar
