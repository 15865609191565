import React from "react";
import { Container, Row, Col, UncontrolledCollapse } from "reactstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Fade from "react-reveal/Fade";

const questions = [
  {
    question: "Does Sunlight provide a ticketing system?",
    answer:
      "No, Sunlight integrates with ticketing systems to process the ticket meta-data and content to generate actionable insights."
  },
  {
    question: "How long does it take to implement Sunlight?",
    answer:
      "Implementing Sunlight is very easy. We install in seconds and can generate actionable insights within minutes."
  },
  {
    question: "Can I customize the NPS template and frequency?",
    answer:
      "Yes, Sunlight supports transactional, adhoc, and scheduled NPS. You can define and use multiple templates."
  },
  {
    question: "Can I get customized analytics, insights, and Bubble?",
    answer:
      "Yes, Sunlight works very closely with every customer to realize the full potential of AI, analytics and automation."
  }
];

const Faqs = () => {
  return (
    <section className="section faqs-dd bg-light edge">
      <div className="shapes-container">
        <div className="absolute icon">
          <Fade top left>
            <FontAwesomeIcon icon={["fas", "question"]} />
          </Fade>
        </div>
      </div>

      <Container>
        <div className="section-heading text-center">
          <h2 className="heading-line">Frequently Asked Questions</h2>
          <p className="lead">Want to learn more about our services?</p>
        </div>

        <Row>
          <Col lg="8" className="mx-lg-auto">
            {questions.map((faq, i) => (
              <Fade bottom key={i}>
                <div className="card shadow-box shadow-hover mb-3" key={i}>
                  <div className="card-header py-3">
                    <Link
                      to="#"
                      className="card-title collapsed"
                      id={`toggler-${i}`}
                    >
                      {faq.question}
                    </Link>
                  </div>

                  <UncontrolledCollapse toggler={`#toggler-${i}`}>
                    <div className="card-body">{faq.answer}</div>
                  </UncontrolledCollapse>
                </div>
              </Fade>
            ))}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Faqs;
