import React from "react" 
import { Container,Form, FormGroup, FormFeedback, Label,Input,Button,Alert } from "reactstrap";
import {baseUrl} from './SharedData';
import axios from 'axios';
import {CountryDropdown} from 'react-country-region-selector';


// shared page components 
 
// core components 
import DefaultNavbar from "../../components/Navbars/DefaultNavbar" 
import DefaultFooter from "../../components/Footers/DefaultFooter" 
import "@fortawesome/fontawesome-free/js/all" 
// Form validation 
class ContactUs extends React.Component { 
    constructor(props) {   
       super(props)
        this.state={ 
            name:'', 
            email:'', 
            phone:'', 
            companyname:'', 
            jobTitle:'', 
            comments:'',
            country:'',
            inputState:'Pricing Information',
            status:'', 
            touched:{ 
                name:false, 
                email:false, 
                phone:false, 
                companyname:false, 
                jobTitle:false, 
                comments:false,
                country:false,          
            },
            

        };
        
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validate = this.validate.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.selectCountry=this.selectCountry.bind(this);
     
      
    } 
    handleInputChange(event){ 
        const target=event.target; 
        const value=target.value; 
        const name=target.name; 
        this.setState({ 
            [name]:value 
        }); 
    } ;
   
    handleSubmit(event){ 
        let payload = {
            name: this.state.name, 
            email:this.state.email, 
            phone:this.state.phone, 
            companyname:this.state.companyname, 
            jobTitle:this.state.jobTitle, 
            comments:this.state.comments,
            inputState:this.state.inputState,
            country:this.state.country,
             
        }
       let data= {
            ...payload,
            preferredDate1: null,
            preferredDate2: null,
            from:"APP"
          }
          axios({
            method: "POST",
            url: baseUrl,
            data:data,
          }) 
        .then(response=>{
            console.log(response)
            this.setState({
                status:"Success"
            })
        })
        .catch(error=>{
            console.log(error)
            this.setState({
                status:"Error"
            })
          
        })
        event.preventDefault();
        this.setState({
            name:'',
            email:'',
            phone:'',
            companyname:'',
            jobTitle:'',
            comments:'',
            country:'',
            inputState:'Pricing Information', 
            touched:{ 
                name:false, 
                email:false, 
                phone:false, 
                companyname:false, 
                jobTitle:false, 
                comments:false,
                country:false,       
            },
        });       
    } ;
    selectCountry (val) {
        this.setState({ country: val });
      }
    handleBlur=(field)=>(event)=>{ 
        this.setState({ 
            touched: {...this.state.touched,[field]:true} 
        }) 
    } 
    validate(name,email, phone,companyname,jobTitle,comments,country){ 
        const errors={ 
            name:'',
            email:'',
            phone:'',
            companyname:'',
            jobTitle:'',
            comments:'',
            country:'',
        } 
        if(this.state.touched.name && name.length<3) 
          errors.name='Full Name should be >3 characters' 
        if(this.state.touched.email && email.split('').filter(x=>x==='@').length!==1) 
           errors.email="Please enter a valid email"  
        if(this.state.touched.email && email.split('').filter(x=>x==='.').length<1)  
           errors.email="Please enter a valid email" 
        const reg=/^\d+$/;   
        if(this.state.touched.phone && !reg.test(phone)) 
           errors.phone="Phone number should contain only numbers"  
        if(this.state.touched.companyname && companyname.length<4)
           errors.companyname='Company Name should be >3 characters';
        if(this.state.touched.jobTitle && jobTitle.length<2)
           errors.jobTitle='Job Title should be >=2 characters'  
        
        if(this.state.touched.comments && comments.length<3)
            errors.comments='Message should be >3 characters' 
        if(this.state.touched.country && country.length<2)
            errors.country="Country name should be >1 character"
            
       return errors;     
    };
 
    render(){ 
        // window.scrollTo(0,0);
        const errors=this.validate(this.state.name,this.state.email,this.state.phone,this.state.companyname,this.state.jobTitle,this.state.comments,this.state.country); 
        return ( 
            <> 
                 <DefaultNavbar inner={true} /> 
                <main ref={React.createRef("main"   )}> 
                    <div className="header alter3-header section"> 
                        <Container> 
                            <h2 className="h1-responsive font-weight-bold text-center my-4"> 
                                Contact us 
                            </h2> 
                             <p className="text-center w-responsive mx-auto mb-5"> 
                             Questions? Please do not hesitate to contact us directly. Our team will respond to you within a matter of hours to assist. 
                             </p> 
                            <div className="row"> 
                                <div className="col-md-9 mb-md-0 mb-5"> 
                                    <Form onSubmit={this.handleSubmit} 
                                        id="contact-form" 
                                        name="contact-form" 
                                        method="POST"
                                    > 
                                        <div className="row"> 
                                            <div className="col-md-6"> 
                                                <div className="md-form mb-0"> 
                                                    <FormGroup> 
                                                    
                           
                                                <Label htmlFor="fullname"> 
                                                        Full Name
                                                       
                                                    </Label> 
                                                    <Input 
                                                        type="text" 
                                                        id="name" 
                                                        name="name" 
                                                        value={this.state.name} 
                                                        onChange={this.handleInputChange} 
                                                        invalid={errors.name !== ''} 
                                                        onBlur={this.handleBlur('name')} 
                                                    > </Input>
                                                    <FormFeedback>{errors.name}</FormFeedback> 
                                                    </FormGroup> 
                                                </div> 
                                            </div> 
                                            <div className="col-md-6"> 
                                                <div className="md-form mb-0"> 
                                             <FormGroup> 
                                                <Label htmlFor="email"> 
                                                        Business Email 
                                                    </Label> 
                                                    <Input 
                                                        type="text" 
                                                        id="email" 
                                                        name="email" 
                                                        value={this.state.email} 
                                                        invalid={errors.email !==''} 
                                                        onBlur={this.handleBlur('email')} 
                                                        onChange={this.handleInputChange} 
                                                    /> 
                                                    <FormFeedback>{errors.email}</FormFeedback> 
                                                      </FormGroup> 
                                                </div> 
                                            </div> 
                                            <div className="col-md-6"> 
                                                <div className="md-form mb-0"> 
                                                <FormGroup>
                                                <Label htmlFor="phone"> 
                                                Business Phone 
                                                       </Label> 
                                                    <Input 
                                                        type="text" 
                                                        id="phone" 
                                                        name="phone" 
                                                        value={this.state.phone}
                                                        invalid={errors.phone !==''}
                                                        onBlur={this.handleBlur('phone')}
                                                        onChange={this.handleInputChange}
                                                    >
                                                      
                                                    </Input>
                                                    
                                                    <FormFeedback>{errors.phone}</FormFeedback>
                                                     </FormGroup>
                                                </div> 
                                            </div> 
                                            <div className="col-md-6"> 
                                                <div className="md-form mb-0"> 
                                                <FormGroup>
                                                <Label htmlFor="companyname"> 
                                                        Company Name 
                                                    </Label> 
                                                    <Input 
                                                        type="text" 
                                                        id="companyname" 
                                                        name="companyname" 
                                                        value={this.state.companyname}
                                                        invalid={errors.companyname !==''}
                                                        onChange={this.handleInputChange}
                                                        onBlur={this.handleBlur('companyname')}
                                                    />
                                                    <FormFeedback>{errors.companyname}</FormFeedback> 
                                                </FormGroup>
                                                </div> 
                                            </div> 
                                            <div className="col-md-6"> 
                                                <div className="md-form mb-0"> 
                                                <FormGroup>
                                                <Label htmlFor="jobTitle">Job Title</Label> 
     
                                                    <Input 
                                                        type="text" 
                                                        id="jobTitle" 
                                                        name="jobTitle" 
                                                        value={this.state.jobTitle}
                                                        invalid={errors.jobTitle !==''}
                                                        onChange={this.handleInputChange}
                                                        onBlur={this.handleBlur('jobTitle')}
                                                    /> 
                                                    <FormFeedback>{errors.jobTitle}</FormFeedback>
                                                    </FormGroup>
                                                </div> 
                                                
                                            </div> 
                                            <div className="col-md-6"> 
                                                <div className="md-form mb-0">
                                                <FormGroup> 
                                                <Label htmlFor="country">Country</Label>    
                                                <CountryDropdown valid={errors.country===''} style={{minHeight:"37px", width:"100%",border: "1px solid #E1DADA"}}
                                                   value={this.state.country}
                                                   onChange={(val) => this.selectCountry(val)} />
                                              
                                                 </FormGroup>
                                                </div> 
                                            </div> 
                                        </div> 
     
                                        <div className="row"> 
                                            <div className="col-md-12"> 
                                                <div className="md-form mb-0"> 
                                                <FormGroup>
                                                <Label htmlFor="comments">Message</Label> 
     
                                                    <Input 
                                                        type="textarea" 
                                                        id="comments" 
                                                        name="comments" 
                                                        rows={4} 
                                                        value={this.state.comments}
                                                        
                                                        invalid={errors.comments !==''}
                                                        onBlur={this.handleBlur('comments')}
                                                        onChange={this.handleInputChange}
                                                    >
                                                        
                                                        </Input> 
                                                       
                                                    <FormFeedback>{errors.comments}</FormFeedback>
                                                    </FormGroup>
                                                </div> 
                                            </div> 
                                        </div> 
                                        {/* <div className="row"> 
                                            <div className="col-md-12"> 
                                                <div className="md-form"> 
                                                <FormGroup>
                                                        <Label for="inputState"> 
                                                            I am interested in :- 
                                                        </Label> 
                                                      
                                                        <Input type="select" name="inputState" id="inputState"
                                                value={this.state.inputState}
                                                onChange={this.handleInputChange}>
                                                <option> Pricing Information </option>
                                                <option>Product Information </option>
                                                <option> Other</option> 
                                            </Input>
                                                        
                                                        </FormGroup>
                                                </div> 
                                            </div> 
                                        </div>  */}
                                     <div className="row">
                                    <div className="col-md-2"> 
                                        <Button className="btn btn-primary" type="submit" disabled={!this.state.name || !this.state.email || !this.state.phone || !this.state.companyname}>Submit</Button> 
                                    </div>
                                    <div className="col-md-10" >
                                    <div>{this.state.status==="Success"?<Alert color="success">"Thank you for contacting us! We will contact you ASAP."</Alert>:null
                                }</div>
<div>{this.state.status==="Error"?<Alert color="danger">"Error ocurred at our end. Please try later!"</Alert>:null}</div>    
</div>
                                        </div> 
                                        
                                    </Form> 
                                    <div className="status" /> 
                                </div> 
                                <div className="col-md-3 text-center"> 
                                    <ul className="list-unstyled mb-0"> 
                                        <li> 
                                            <i className="fas fa-map-marker-alt fa-2x" /> 
                                            <hr /> 
                                            <b>Sunlight Technologies, Inc</b> 
                                            <p> 
                                                5505 E Santa Ana Canyon Rd # 18972 Anaheim, CA 
                                                92817, USA 
                                            </p> 
                                        </li> 
                                        {/* <li> 
                                            <i className="fas fa-map-marker-alt mt-4 fa-2x" /> 
                                            <hr /> 
                                            <b>Sunlight Technologies pvt ltd</b> 
                                            <p> 
                                                C-3,Olive County Vasundhara, Ghaziabad UP, 
                                                201012, India 
                                            </p> 
                                        </li>  */}
                                        <li> 
                                            <i className="fas fa-envelope mt-4 fa-2x" /> 
                                            <hr /> 
                                            <b>info@thesunlight.ai</b> 
                                            <br /> 
                                            <b>sales@thesunlight.ai</b> 
                                            <br /> 
                                            <b>support@thesunlight.ai</b> 
                                        </li> 
                                    </ul> 
                                </div> 
                            </div>
                            </Container> 
                    </div> 
                </main> 
                <DefaultFooter /> 
            </> 
        ) 
    } 
     
} 
export default ContactUs 
