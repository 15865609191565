import React,{useEffect} from "react"
import { Container } from "reactstrap"
import Fade from "react-reveal/Fade"

// core components
import DefaultNavbar from "../../components/Navbars/DefaultNavbar"
import DefaultFooter from "../../components/Footers/DefaultFooter"

export default () => {
    useEffect(()=>{
        window.scrollTo(0,0);
    })
    return (
        <>
            <DefaultNavbar inner={true}/>
            <main ref={React.createRef("main")}>
                <div className="header alter3-header section">
                    <div className=" powered-design">
                        <div className="shapes-container">
                            <div className="shape shape-circle shape-circle-1">
                                <Fade bottom right duration={1500}>
                                    <div />
                                </Fade>
                            </div>
                            <div className="shape shape-circle shape-circle-2">
                                <Fade bottom right duration={1200} delay={500}>
                                    <div />
                                </Fade>
                            </div>
                            <div className="shape shape-ring animation--rotating-diagonal">
                                <div />
                            </div>
                            <div className="shape shape-triangle shape-animated">
                                <div className="animation--rotating" />
                            </div>
                            <div className="shape pattern-dots-1" />
                        </div>
                    </div>

                    <Container>
                        <h1 className="heading-line">Career</h1>
                        <p
                            style={{ whiteSpace: "pre-wrap" }}
                            className="lead text-alternate"
                        >
                            {`
We have the following positions open:

● ReactJs front end developer
● Python developer
● Inside sales representative

If you are interested in working with us and love to work in a rapid development environment, please reach out to:  info@thesunlight.ai`}
                            {/* do not changes this text formate */}
                        </p>
                    </Container>
                </div>
            </main>
            <DefaultFooter />
        </>
    )
}
