import React,{useEffect} from "react"
import { Container, Row, Col } from 'reactstrap';

// core components
import DefaultNavbar from '../../components/Navbars/DefaultNavbar';
import DefaultFooter from '../../components/Footers/DefaultFooter';
import Animationfade from 'views/shared/Animationfade';

import imgurl from '../../assets/img/SunlightAbout.png'

export default () => {
  useEffect(()=>{
    window.scrollTo(0,0);
})
  return (
    <>
      <DefaultNavbar inner={true} />
      <main ref={React.createRef('main')}>
        <div className='header alter3-header section'>
          <Animationfade />
          <Container>
            <Row>
            <Col lg='7'>
            <h1 className='heading-line'>About</h1>
              <p
                style={{ whiteSpace: 'pre-wrap' }}
                className='lead'
              >
                {`
Sunlight employs powerful AI technology to manage and optimize support processes, people and productivity to deliver high quality and low-cost support services.

Sunlight helps organizations transform their customer support and deliver exceptional customer satisfaction through automation, data analytics and AI.

Sunlight’s approach is simple: Extract, Analyze, Predict and Prescribe. We provide actionable insights, empower customer support agents with AI, and capture the customer voice through transactional and campaign-based Net Promoter Scores.

Our customers realize the benefits of quality and superior customer support, with efficient customer service at reduced cost and improved profitability.

`}
                {/* do not changes this text formate */}
              </p>
            </Col>
            <Col lg='5'>
                <div style={{ height: "500px", marginTop:'140px', marginRight: '20px' }} className="rounded overflow-hidden ">
                    <img src={imgurl} className="img-responsive" alt="" />
                </div>
            </Col>
            </Row>
          </Container>
        </div>
      </main>
      <DefaultFooter />
    </>
  );
};
