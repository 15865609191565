import React from "react"
import { Link } from "react-router-dom"

// reactstrap components
import { NavLink, Nav, Container, Row, Col } from "reactstrap"

const DefaultFooter = () => {
    return (
        <footer className="site-footer section bg-dark text-contrast ">
            <Container className="py-3">
                <Row className="row gap-y text-center text-md-left">
                    <Col md="4" className="mr-auto">
                        <img
                            src={require("assets/img/sunlight_logo.png")}
                            alt=""
                            className="logo"
                        />

                        <p>©{new Date().getFullYear()} - Form | All rights reserved</p>
                    </Col>

                    <Col md="5">
                        <nav className="nav justify-content-around">
                            <Nav className="flex-column" tag="nav">
                            

                                {/*<NavLink className="py-2 text-contrast" tag={Link} to="/career">
                                    Career
                                </NavLink>

                                 <NavLink className="py-2 text-contrast" tag={Link} to="/blog">
                                    Blog
                                </NavLink>

                                <NavLink className="py-2 text-contrast" tag={Link} to="/case-study">
                                    Case Study
                                </NavLink> */}

                                <NavLink className="py-2 text-contrast" tag={Link} to="/contactus">
                                    Contact Us
                                </NavLink>
                            </Nav>
                        </nav>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default DefaultFooter
