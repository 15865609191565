import React,{useEffect} from "react"
import { Container, Row, Col } from 'reactstrap';

// core components
import DefaultNavbar from '../../components/Navbars/DefaultNavbar';
import DefaultFooter from '../../components/Footers/DefaultFooter';
import Animationfade from 'views/shared/Animationfade';

import imgurl from '../../assets/img/integration.png';

export default () => {
  useEffect(()=>{
    window.scrollTo(0,0);
})
  return (
    <>
      <DefaultNavbar inner={true} />
      <main ref={React.createRef('main')}>
        <div className='header alter3-header section'>
          <Animationfade />
          <Container>
            <Row>
              <Col lg='12'>
                <h1 className='heading-line'>
                Our integrations
                </h1>
                <p style={{ whiteSpace: 'pre-wrap' }} className='lead'>
                  {`Sunlight seamlessly integrates with popular ticketing systems such as Freshdesk, Zendesk, ServiceNow and MS Dynamics CRM`}
                  {/* do not changes this text formate */}
                </p>
                <p style={{ whiteSpace: 'pre-wrap' }} className='lead'>
                  {`We can integrate with any platform on-demand, including Salesforce and Freshdesk`}
                  {/* do not changes this text formate */}
                </p>
              </Col>
              <Col lg='12'>
                <div
                  className='rounded overflow-hidden '
                >
                  <img src={imgurl} className='img-responsive' alt='' />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </main>
      <DefaultFooter />
    </>
  );
};
