import React, { useState } from 'react';
import { Container, Row, Col,Nav,NavLink } from 'reactstrap';
import Slider from 'react-slick';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PowerfulCard from './PowerfulCard.jsx';


// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function PowerfulFeatures() {
  const config = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const [settings, setSettings] = useState(config);

  let cards = [
    { title: "Sunlight", img: "slide1.png"},
    { title: "Manager's Dashboard", img: "slide2.png"},
    { title: "Ticket Prioritization Center", img: "slide3.png"},
    { title: "Auto NPS Survey", img: "slide4.png"},
    { title: "Sunlight Drilldown", img: "slide5.png"},
    { title: "Sunlight Bubble", img: "slide6.png"}
  ];



  return (
    <section className='section alter3-features'>
      <div className='shapes-container'>
        <div className='shape shape-ring shape-ring-1'>
          <div className='animation animation--rotating' />
        </div>
        <div className='shape shape-ring shape-ring-2'>
          <div className='animation animation--rotating' />
        </div>
        <div className='shape shape-circle animation--clockwise'>
          <div />
        </div>
        <div className='shape background-shape-main' />
      </div>

      <Container>
        <Row>
          {/* <FontAwesomeIcon icon={['fas', 'dot-circle']} className='icon' /> */}
          <Col lg='5' className='order-lg-last'>
            <div className='section-heading mt-5'>
              <h3 className='text-alternate heading-line'>
              Take control of your customer service quality and crush your metrics

            
              </h3>
              <p className='lead bold'>
              Effortlessly slice and dice your data in real-time to assess issues as they’re happening
              </p>
              <p className="bold">
                <ul>
                <li>
               <div>
               Markedly improve NPS/CSAT with real-time issue identification and scores tied back to agents
                </div>
                </li>
                <li>Quickly provide agents the latest knowledge in real time with AI to get the right answer the first time
                  </li>
 <li>
                  <span>
                  Identify your bottlenecks before they’re a problem and dramatically improve customer service productivity
                </span>
                </li>
                <li>
                Give us 20 minutes to install, and watch your data show you the power of Sunlight
                </li>
                </ul>
                </p>
            

              <Nav className="mt-5" tag="nav">
              <NavLink
                href="/contactus"
                className="mr-3 btn btn btn-rounded btn-primary"
              >
                Request demo
              </NavLink>

              <NavLink
                        href="/contactus"
                        className="btn btn-rounded btn-outline-primary"
                    >
                        Try Sunlight free for 60 days
                    </NavLink>
            </Nav>
            </div>
          </Col>

          <Col lg='7' className='pr-lg-6' style={{marginTop:"180px"}}>
            <Slider {...settings}>
              {cards.map((x, i) => {
                return (
                  <Col className='test' lg='12'>
                    <PowerfulCard {...x} key={i} />
                  </Col>
                );

              })}
            </Slider>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default PowerfulFeatures;
