import React from "react";
import { Container, Row, Col, Nav, NavLink } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IsometricMockups from "../home/IsometricMockups"

const Heading = () => {
  return (
    <header className="header alter3-header section gradient gradient-primary-auxiliary text-contrast">
      <div className="shapes-container">
        <div className="animation-shape shape-ring animation--rotating-diagonal">
          <div />
        </div>
        <div className="animation-shape shape-circle animation--clockwise">
          <div />
        </div>
        <div className="animation-shape shape-triangle animation--anti-clockwise">
          <div className="animation--rotating" />
        </div>
        <div className="animation-shape shape-diamond animation--anti-clockwise">
          <div className="animation--rotating" />
        </div>
        <div className="static-shape shape-ring-1" />
        <div className="static-shape shape-ring-2" />
        <div className="static-shape shape-circle-1" />
        <div className="static-shape pattern-dots-1" />
        <div className="static-shape pattern-dots-2" />
        <div className="static-shape ghost-shape ghost-shape-1" />
      </div>
      <Container className="bring-to-front">
        <Row className="flex-column flex-md-row align-items-center justify-content-center mx-auto">
          <Col className="flex-column align-items-center justify-content-center mx-auto">
            <div className="nav flex-column flex-md-row align-items-center justify-content-center bold mx-auto">
              <h1 className="extra-bold display-md-3 font-md">
                <span className="main_heading d-block  display-md-4 light mt-250">
                Improved Agent Efficiency.<br/>
                <span className="nav flex-column flex-md-row align-items-center justify-content-center mx-auto">
                Happier Customers.</span>
                </span>
              </h1>
            </div>
            <div className="nav flex-column flex-md-row align-items-center justify-content-center mx-auto">
              <p className="lead">
              Real-time, agent-based CSAT/NPS data. AI-driven, real-time analytics. 
               <br />
                <span className="nav flex-column flex-md-row align-items-center justify-content-center mx-auto">
                  We’re taking your customers to a new level of satisfaction.</span>
              </p>
            </div>
            <div className="nav flex-column flex-md-row align-items-center justify-content-center mx-auto">
              <IsometricMockups />
            </div>

            <div className="nav flex-column flex-md-row align-items-center justify-content-center mx-auto">
              <Nav tag="nav">
                <NavLink
                  href="/contactus"
                  className="mr-3 btn btn btn-rounded btn-contrast"
                >
                  <FontAwesomeIcon icon={["fas", "tag"]} className="mr-3" />
                  Request demo
                </NavLink>
                <NavLink
                  href="/contactus"
                  className="btn btn-rounded btn-primary"
                >
                  Try Sunlight free for 60 days
                </NavLink>
              </Nav>
            </div>

          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default Heading;
